import { getPalestra, getPalestraByHost } from "../DAO/palestraDAO";

export const serverInfo = {
  name: localStorage.getItem('server')?'http://192.168.1.2:8080/wp':'https://be.annotatio.online/wp',
  namesec: localStorage.getItem('server')?'http://192.168.1.2:8080':'https://be.annotatio.online',
  domain: window&&window.location&&window.location.hostname&&(window.location.hostname.includes('localhost')||window.location.hostname.includes('192.'))?'annotatio.online':window.location.hostname

}

  export default serverInfo;

  export const palestraDefault = {
    "id":1,
}

  export const versione = {
    androidVersion:'1.0.0', //nel file reactnativePhysico/android/app/build.gradle ==> android { defaultConfig { versionName 
    androidVersionCode:'1', //nel file reactnativePhysico/android/app/build.gradle ==> android { defaultConfig { versionName
    iosVersion:'1.0.0' // reactnativePhysico.xcodeproj ==> general ==> target ==> reactnativePhysico ==> Identity ==> version
  }

  export function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
  
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  export async function getPalestraFromUrl(token){
    let palestratemp={}
    if(global.config.domain.indexOf('localhost')>=0){
      if (palestraDefault&&palestraDefault.id&&palestraDefault.id>0){
        palestratemp=await getPalestra(palestraDefault.id);
      }
    }
    else{
        palestratemp=await getPalestraByHost(global.config.domain, token);
      }
      return palestratemp
  }

  export async function getPalestraSocioFromUrlEmail(palestreassociate, token){
    console.log('getPalestraSocioFromUrlEmail')
    let palestratemp={}
      if(global.config.domain.indexOf('localhost')>=0){
        if (palestraDefault&&palestraDefault.id){
          palestratemp=palestraDefault;
        }
      }
      else{
        palestratemp=await getPalestraByHost(global.config.domain, token);
      }
      if (!palestratemp||!palestratemp.id){
        palestratemp=global.localstorage.palestrasocioSelezionata?global.localstorage.palestrasocioSelezionata.palestra:""
      }
      return palestreassociate.filter(paltemp => paltemp.palestra.id===palestratemp.id)[0]
  }