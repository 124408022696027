import React from "react";
import { useState } from "react";
import {useDispatch} from 'react-redux';
import { navigate } from "hookrouter";
import { confermacodiceloginapp, loginapp } from "../DAO/anagraficaDAO";
import { setEmailLoggato } from "../STORE/reducers/gestoreUtente";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function InserimentoCodice(props){
    //const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    const [codicelogin, setcodicelogin] = useState('');
    const [captchatoken, setcaptchatoken] = useState()

    const dispatch = useDispatch();

    function invianuovocodice(){
        async function loginforapp() {
          const anagesistente = await loginapp(
            localStorage.getItem("emaildaconfermare"),
            captchatoken
          );
          if (anagesistente){
            alert('Nuovo codice inviato')
          }
          else{
            alert('Email non trovata')
          }
          
        }
        loginforapp();
      }
  
      function confermacodicelogin() {
          if (codicelogin){
              console.log('confermacodicelogin');
              async function confermalogincodice() {
              const confermato = await confermacodiceloginapp(
                localStorage.getItem("emaildaconfermare"),
                  codicelogin,
                  '',
                  captchatoken
              );
              if (confermato){
                //const anags = await getAnagraficheCorrelate(localStorage.getItem("emaildaconfermare"),'');
  
                //dispatch(setanagrafica(anags[0]))
                //localStorage.setItem('idanag',""+anags[0].id);
                
                dispatch(setEmailLoggato(localStorage.getItem("emaildaconfermare")));
                localStorage.setItem("email",localStorage.getItem("emaildaconfermare"))
                localStorage.removeItem("emaildaconfermare")
                navigate('/annotatio-home/');
              }
              else{
              alert('Message','Codice non valido')
              }
        }
        confermalogincodice();
      }
      else{
        alert('Attenzione',"specificare il codice inviato per email");
      }
    }
  
      return(
        <GoogleReCaptchaProvider reCaptchaKey="6LeLySYaAAAAAAkxF-AI4qDkwEqoYqhfPlwA88ut">
          <GoogleReCaptcha
                        onVerify={token => {
                          setcaptchatoken(token);
                        }}
                  />
          <div style={{backgroundColor:'white', margin:70}}>
            <div style={{fontSize: 20}}>Abbiamo inviato una email a <div style={{fontStyle:'italic', fontWeight:'bold'}}>{localStorage.getItem("emaildaconfermare")}</div> con il codice di accesso. Inseriscilo qui</div>
            <div style={{width:100, justifyContent:'center', alignContent:'center',alignItems:'center',alignSelf:'center'}}>
            <input type="text" placeholder='_ _ _ _ _' value={codicelogin} maxLength={5} style={{flex:1}} keyboardType='numeric'  onChange={(e) => setcodicelogin(e.target.value)} />
            </div>
            
            <button onClick={() => confermacodicelogin()}>Conferma</button>
            <button onClick={() => invianuovocodice()}>Invia un altro codice</button>
            <button onClick={() => {localStorage.removeItem('emaildaconfermare'); dispatch(setEmailLoggato('')); navigate('/annotatio-login/')}}>Cambia email</button>
           
          </div>
          </GoogleReCaptchaProvider>
      );
  }