import React from "react";
import '../util/config'
import { useState } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { insutente } from "../DAO/utenteDAO";
import gettoken from "../DAO/tokenDAO";
import { insAnagrafica } from "../DAO/anagraficaDAO";
import { getPalestraFromUrl } from "../util/globals";
import { useEffect } from "react";


export default function NuovoUtenteAnagrafica (){
  const [captchatoken, setcaptchatoken] = useState()
  const [palestra, setpalestra] = useState(null)

  const [email, setemail] = useState('')
  const [nome, setnome] = useState('')
  const [cognome, setcognome] = useState('')

  const [sesso, setsesso]= useState('')
  const [dataNascita, setdataNascita]= useState('')
  const [luogonascita, setluogonascita]= useState('')
  const [codiceFiscale, setcodiceFiscale]= useState('')
  const [indirizzo, setindirizzo]= useState('')
  const [citta, setcitta]= useState('')
  const [cap, setcap]= useState('')
  const [telefono, settelefono]= useState('')
  const [cognomeNomeGenitore, setcognomeNomeGenitore]= useState('')
  const [dataNascitaGenitore, setdataNascitaGenitore]= useState('')
  const [codiceFiscaleGenitore, setcodiceFiscaleGenitore]= useState('')
  const [indirizzoGenitore, setindirizzoGenitore]= useState('')
  const [cittaGenitore, setcittaGenitore]= useState('')
  const [capGenitore, setcapGenitore]= useState('')

  const [autorizzoprivacy, setautorizzoprivacy] = useState(false)
  const [autorizzoregolamento, setautorizzoregolamento] = useState(false)
  const [autorizzoimmagini, setautorizzoimmagini] = useState(false)
      
  function handleSubmit(event) {
    async function inserisciute(){
      event.preventDefault();
      if (!email.trim()){
        alert('Email obbligatoria');
        return
      }
      if (!nome.trim()){
        alert('Nome obbligatorio');
        return
      }
      if (!cognome.trim()){
        alert('Cognome obbligatorio');
        return
      }
      if (!telefono.trim()){
        alert('Telefono obbligatorio');
        return
      }
      if (!autorizzoprivacy){
        alert('É necessario autorizzare il trattamento dei dati personali');
        return
      }
      if (!autorizzoimmagini){
        alert('É necessario autorizzare il consenso alle immagini');
        return
      }
      if (!autorizzoregolamento){
        alert('É necessario autorizzare il consenso al regolamento');
        return
      }
      try{
        //const token = await gettoken(email,null)
        //console.log("HEADERS: "+token.headers)
        const result=await insutente(email, nome, cognome, telefono, captchatoken)
        console.log(result);
        if (result&&result.id&&result.id>0){
            const newtoken=await gettoken(result.email)
            const paltemp=await getPalestraFromUrl(newtoken)
            setpalestra(paltemp)
            if (paltemp){
            const anagrafica={
                nome: nome,
                cognome: cognome,
                sesso: sesso,
                datanascita: dataNascita,
                luogonascita: luogonascita,
                codiceFiscale: codiceFiscale,
                indirizzo: indirizzo,
                citta: citta,
                cap: cap,
                telefono: telefono,
                email: email,
                cognomeNomeGenitore: cognomeNomeGenitore,
                dataNascitaGenitore: dataNascitaGenitore,
                codiceFiscaleGenitore: codiceFiscaleGenitore,
                indirizzoGenitore: indirizzoGenitore,
                 cittaGenitore: cittaGenitore,
                 capGenitore: capGenitore
            }
            const resultInsAnag=await insAnagrafica(email, paltemp.id, anagrafica, newtoken)
            if (resultInsAnag&&resultInsAnag.id>0){
                alert('Anagrafica registrata correttamente')
            }
            window.location.href="/"
            }
        }
      }
      catch(err){
        console.log(err)
        alert(err.response.data.message)
      }
      
    }
    inserisciute();
  }

  function getpal(){
    async function getpalesss(){
        const pal=await getPalestraFromUrl(null)
        setpalestra(pal)
    }
    getpalesss()
  }

  useEffect(() =>{
    getpal()
  },[])

	    return (
	    <div  className="container">
        <h4>Registrazione {palestra?palestra.palestra:""} </h4>
       
            <GoogleReCaptchaProvider 
            reCaptchaKey="6LeLySYaAAAAAAkxF-AI4qDkwEqoYqhfPlwA88ut">

                <GoogleReCaptcha onVerify={token => {setcaptchatoken(token);}} />
                <form id="formAnag" onSubmit={handleSubmit}>
                    <div className="form-row">
                    <div className="col-md-4">
                    <div class="form-group">
                    <label>Email</label>
                        <input type="text" className="form-control" placeholder="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                    </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-md-4">
                    <div className="form-group">
                    <label className="control-label">Nome</label>
                    <input placeholder="Nome" className="form-control" type="text" size="15" name="nome" value={nome} required="required" onChange={(e) => setnome(e.target.value)}/>
                    </div>
                    </div>
                    
                    <div className="col-md-4">
                    <div className="form-group">
                    <label className="control-label">Cognome</label>
                    <input placeholder="Cognome" className="form-control" type="text" size="14" name="cognome" value={cognome} required="required" onChange={(e) => setcognome(e.target.value)}/>
                    </div>
                    </div>
                    <div className="col-md-1">
                    <div className="form-group">
                    <label className="control-label">Sesso</label>
                    <select className="form-control" name="sesso" placeholder="sesso" value={sesso}  onChange={(e) => setsesso(e.target.value)}>
                            <option></option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                            </select>
                    </div>
                    </div>
                    
                </div>
                <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                    <label>Data di nascita <small className="text-muted">yyyy-mm-dd</small></label>
                    <input type="date" name="dataNascita" className="form-control date-inputmask" id="date-mask" placeholder="Enter Date" value={dataNascita} required="required" onChange={(e) => setdataNascita(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label>Luogo nascita</label>
                    <input type="text" name="luogonascita" className="form-control" placeholder="luogonascita" value={luogonascita} onChange={(e) => setluogonascita(e.target.value)}/>
                </div>
                    </div>
                    <div className="col">
                    <div className="form-group">
                    <label className="control-label">Codice fiscale</label>
                    <input type="text" className="form-control" name="codiceFiscale" maxLength="16" value={codiceFiscale}  onChange={(e) => setcodiceFiscale(e.target.value)}/>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="control-label">Indirizzo</label>
                    <input placeholder="via" type="text" name="indirizzo" className="form-control" value={indirizzo} maxLength="255" onChange={(e) => setindirizzo(e.target.value)}/>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                    <label className="control-label">Città</label>
                    <input className="form-control" placeholder="Citt&agrave;" type="text" name="citta" value={citta} maxLength="100" onChange={(e) => setcitta(e.target.value)}/>
                    </div>
                    </div>
                    <div className="col">
                    <div className="form-group">
                    <label className="control-label">CAP</label>
                    <input className="form-control" type="text" size="8" maxLength="5" name="cap" value={cap} onChange={(e) => setcap(e.target.value)}/>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <div className="form-group">
                    <label className="control-label">Tel</label>
                    <input className="form-control" type="tel" value={telefono} maxLength="24" name="telefono" required="required" placeholder="Telefono" onChange={(e) => settelefono(e.target.value)}/>
                    </div></div>
                    <div className="col">
                    <div className="form-group">
                    <label className="control-label">Email </label>
                    &nbsp;<strong>{email}</strong>
                    </div>
                    </div>
                </div>
            
            
                <div className="row">
                    <div className="col">
                    <h4>Eventuali dati del genitore/tutore</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <label className="control-label">Cognome e Nome</label>
                    <input className="form-control" type="text" name="cognomeNomeGenitore" value={cognomeNomeGenitore} onChange={(e) => setcognomeNomeGenitore(e.target.value)}/>
                    </div>
                    <div className="col">
                        <div className="form-group">
                        <label>Data nascita </label>
                        <input type="date" className="form-control date-inputmask" id="date-mask" placeholder="Data nascita genitore" name="dataNascitaGenitore" value={dataNascitaGenitore} onChange={(e) => setdataNascitaGenitore(e.target.value)}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                    <label className="control-label">Codice fiscale/P. IVA</label>
                    <input className="form-control" type="text" name="codiceFiscaleGenitore" value={codiceFiscaleGenitore} onChange={(e) => setcodiceFiscaleGenitore(e.target.value)}/>
                    </div></div>
                </div>
                <div className="row">
                <div className="col-md-6">
                <div className="form-group">
                <label className="control-label">Indirizzo</label>
                <input className="form-control" type="text" name="indirizzoGenitore" value={indirizzoGenitore} onChange={(e) => setindirizzoGenitore(e.target.value)}/>
                </div>
                </div>
                <div className="col-md-4">
                <div className="form-group">
                <label className="control-label">Città</label>
                <input className="form-control" type="text" name="cittaGenitore" value={cittaGenitore} onChange={(e) => setcittaGenitore(e.target.value)}/>
                </div>
                </div>
                <div className="col">
                <div className="form-group">
                <label className="control-label">CAP</label>
                <input className="form-control" type="text" name="capGenitore" value={capGenitore} onChange={(e) => setcapGenitore(e.target.value)}/>
                </div>
                </div>
                </div>
        </form>
        <hr />
        <label>
            <input type="checkbox" name="autorizzoprivacy" value="1" onChange={(e) => setautorizzoprivacy(e.target.checked)} />
            &nbsp;Ho letto, compreso e accettato <a href={(palestra&&palestra.website)?("https://"+palestra.website+"/privacy-policy/"):"/privacy-policy/"} target="_blank">il regolamento syulla privacy</a> (obbligatorio)
        </label>
        <label>
            <input type="checkbox" name="autorizzoregolamento" value="1" onChange={(e) => setautorizzoregolamento(e.target.checked)} />
            &nbsp;Ho letto, compreso e accettato <a href={(palestra&&palestra.website)?("https://"+palestra.website+"/condizioni-generali-e-regolamento/"):"/condizioni-generali-e-regolamento/"} target="_blank">condizioni generali e regolamento</a> (obbligatorio)
        </label>
        <label>
            <input type="checkbox" name="autorizzoimmagini" value="1" onChange={(e) => setautorizzoimmagini(e.target.checked)} />
            &nbsp;Ho letto, compreso e accettato <a href={(palestra&&palestra.website)?("https://"+palestra.website+"/liberatoria-e-consenso-alla-pubblicazione-e-trasmissione-di-immagini/"):"/liberatoria-e-consenso-alla-pubblicazione-e-trasmissione-di-immagini/"} target="_blank">il consenso alla pubblicazione delle immagini</a> (obbligatorio)
        </label>
        {autorizzoprivacy&&autorizzoregolamento&&autorizzoimmagini&&<button onClick={handleSubmit}>Conferma Registrazione!</button>}
        
        </GoogleReCaptchaProvider>
         
      </div>
        )
}