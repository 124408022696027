import React from "react";
import axios from 'axios';
import '../util/config'
import { useState } from "react";
import { useEffect } from "react";

export default function DettaglioUtente (props){
  const [id, setid] = useState(0)
  const [name, setname] = useState(0)
 
  const [imageUrl, setimageUrl] = useState('')
  const [address, setaddress] = useState('')
  const [address2, setaddress2] = useState('')
  const [vat, setvat] = useState('')
  const [username, setusername] = useState('')
  const [nome, setnome] = useState('')
  const [cognome, setcognome] = useState('')
  const [zip, setzip] = useState('')
  const [datanascita, setdatanascita] = useState('')
  const [sesso, setsesso] = useState('')
  const [city, setcity] = useState('')
  const [telefono, settelefono] = useState('')
  const [nazione, setnazione] = useState('')
  const [visible, setvisible] = useState('')
  const [msg, setmsg] = useState('')

  function handleSubmit(event) {
      event.preventDefault();
      const formData = new FormData();
      formData.append('id',id);
      formData.append('email', props.email);
      formData.append('username', username); //ok
      formData.append('name', name); //ok
      formData.append('nome', nome);//ok
      formData.append('cognome', cognome);//ok
      formData.append('datanascita', datanascita);//ok
      formData.append('sesso', sesso);//ok
      formData.append('city', city);//ok
      formData.append('address', address);//ok
      formData.append('address2', address2);
      formData.append('zip', zip);//ok
      formData.append('telefono',telefono);//ok
      formData.append('nazione',nazione);
      axios.post(global.config.server+'/salvaUser', formData )
      .then((result) => {
        console.log(result.data);
      }).catch(err => {
        if (err.response){
          alert(err.response.data.message);
          setmsg(err.response.data.message)
        }
        else{
          alert(err.response);
          setmsg(err.response)
        }
  });
    }

    useEffect(() =>{
        const formData = new FormData();
        formData.append('email', props.email);
        formData.append('website', global.config.domain);
          axios.post(global.config.server+'/checkUser', formData )
        .then((result) => {
            console.log(result.data);
            setid(result.data.id)
            setname(result.data.name)
            setaddress(result.data.address)
            setaddress2(result.data.address2)
            setvat(result.data.vat)
            setusername(result.data.username)
            setnome(result.data.nome)
            setcognome(result.data.cognome)
            setzip(result.data.zip)
            setdatanascita(result.data.datanascita)
            setsesso(result.data.sesso)
            setcity(result.data.city)
            settelefono(result.data.telefono)
            setnazione(result.data.nazione)
           });
      },[props.email]);

	    return (
       
	    <div className="container-fluid">
        <div className="form-row">
          <div className="col-md-4">
            {props.email}
          </div>
          <div className="col-md-4">
          <div class="form-group">
          <label>Username</label>
            <input type="text" className="form-control" placeholder="USERNAME" name="username" value={username} onChange={(e) => setusername(e.target.value)} />
          </div>
          </div>
          <div Name="col-md-4">
          <div class="form-group">
          <label>Name</label>
            <input type="text"placeholder="NAME" className="form-control"  name="name" value={name} onChange={(e) => setname(e.target.value)} />
          </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
          <div class="form-group">
          <label>Nome</label>
            <input type="text" className="form-control" placeholder="NOME" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
          </div>
          </div>
          <div className="col-md-4">
          <div class="form-group">
          <label>Cognome</label>
            <input type="text" className="form-control" placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />
          </div>
          </div>
          <div Name="col-md-4">
          <div class="form-group">
          <label>Telefono</label>
            <input type="text"placeholder="TELEFONO" className="form-control"  name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} />
          </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
          <div class="form-group">
          <label>Data nascita</label>
            <input type="text" className="form-control" placeholder="DATA NASCITA" name="nome" value={datanascita} onChange={(e) => setdatanascita(e.target.value)} />
          </div>
          </div>
          <div class="form-group">
          <label>Sesso</label>
          <select name="nome" className="form-control" value={sesso} onChange={(e) => setsesso(e.target.value)}>
            <option value="">--Seleziona sesso--</option>
            <option value="F">F</option>
            <option value="M">M</option>
            <option value="O">Altro</option>

          </select>
          </div>
          </div>
          <div className="form-row">
          <div className="col-md-5">
          <div class="form-group">
          <label>Indirizzo</label>
            <input type="text" className="form-control" placeholder="INDIRIZZO" name="nome" value={address} onChange={(e) => setaddress(e.target.value)} />
          </div>
          </div>
          <div className="col-md-3">
          <div class="form-group">
          <label>Citta</label>
            <input type="text" className="form-control" placeholder="CITTA" name="citta" value={city} onChange={(e) => setcity(e.target.value)} />
          </div>
          </div>
          <div className="col-md-1">
          <div class="form-group">
          <label>CAP</label>
            <input type="text" className="form-control" placeholder="CAP" name="cap" value={zip} onChange={(e) => setzip(e.target.value)} />
          </div>
          </div>
          <div className="col-md-3">
          <div class="form-group">
          <label>Nazione</label>
            <input type="text" className="form-control" placeholder="NAZIONE" name="nazione" value={nazione} onChange={(e) => setnazione(e.target.value)} />
          </div>
          </div>
          </div>
         <button onClick={handleSubmit}>SALVA</button>
      </div>
        )
}