import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Login from "./Login";

export default function LoginUtil({showlogin}){

    const email = useSelector(state => state.gestoreUtente.emailloggato);

    function logout(){
        localStorage.clear();
        window.location.href="/annotatio-home"
    }

    return (
        <div>
            {
                (localStorage.getItem('email')||email)
                ?
                <div style={{float:"right"}}>
                <span>{localStorage.getItem('email')?localStorage.getItem('email'):email}</span>
                <span><button onClick={logout}>Logout</button></span>
                </div>
            :
                <div>
                    {!showlogin&&
                    <div>
                    <span><a href="/loginannotatio">Login</a></span> - 
                    <span><a href="/annotatiou-register">Register</a></span>
                    </div>
                    }
                    {showlogin&&<div>
                        <Login />
                    </div>}
                </div>
            }

        </div>
    )
}