import React from "react";

import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { getAnagrafica, getAnagraficheCorrelate, loginapp } from "../DAO/anagraficaDAO";
import { useRoutes, navigate} from "hookrouter";

import {  GoogleReCaptchaProvider, GoogleReCaptcha} from 'react-google-recaptcha-v3';

import { LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login';
  
  import { FacebookLoginButton, GoogleLoginButton} from 'react-social-login-buttons';
import { useCallback } from "react";
import { setEmailLoggato, setanagrafica, settoken } from "../STORE/reducers/gestoreUtente";
import gettoken from "../DAO/tokenDAO";
import { useEffect } from "react";
import { getPalestraFromUrl } from "../util/globals";

const REDIRECT_URI = window.location.href;

export default function Login(){
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const [palestra, setpalestra] = useState(null)
  const [tokenprovider, settokenprovider] = useState(null)
  const [emaildelsocial, setemaildelsocial] = useState(null)
    const [emaillogin, setemaillogin] = useState('')
    const dispatch = useDispatch();

    const [captchatoken, setcaptchatoken] = useState(null)

    function entra(){
        async function loginforapp() {
            const anagesistente = await loginapp(emaillogin, captchatoken, palestra?palestra.id:"");
            console.log(anagesistente)
            if (anagesistente){
              localStorage.setItem('emaildaconfermare',emaillogin);
               //setshowcodice(true)
              navigate('/annotatiou-inscode/');
            }
            else{
              alert('Email non trovata')
            }
          }
          if (emaillogin){
            loginforapp();
          }
    }

    function confermaSocialLogin(){

        async function sociallogin() {
            if (emaildelsocial){
              console.log('vec')
              const anagesistente = await loginapp(emaildelsocial, captchatoken, palestra?palestra.id:"");
              if (anagesistente){
                const result = await gettoken(emaildelsocial,'')
                console.log("HEADERS: "+result.headers)
                window.$t=result.data
                dispatch(settoken(result.data))

                const paltemp=await getPalestraFromUrl(null);
                console.log("paltemp")
                console.log(paltemp,"paltemp")
                if (paltemp&&paltemp.id>0&&paltemp.id!==80){
                  dispatch(setanagrafica(null))
                  localStorage.setItem('idanag',null);
                  const anags=await getAnagraficheCorrelate(emaildelsocial,paltemp.id, result.data)
                  if (anags&&Array.isArray(anags)&&anags.length>0){
                    dispatch(setanagrafica(anags[0]))
                    localStorage.setItem('idanag',anags[0].id);
                  }
                  else{
                    alert("Email "+emaildelsocial+"non registrata in "+paltemp.palestra)
                    return
                  }
                }
              
                
                
                dispatch(setEmailLoggato(emaildelsocial));
                localStorage.setItem("email",emaildelsocial)
                localStorage.removeItem("emaildaconfermare")
                window.location.href='/annotatio-home'
              }else{
                alert("Email "+emaildelsocial+" non registrata "+(palestra?" in "+palestra.palestra:""))
              }
          }
          else {
            alert('No email specified after social login');
          }
        }
        sociallogin()
    }
  
    const onLoginStart = useCallback(() => {
      console.log('login start')
    }, [])
  
    const onLogoutSuccess = useCallback(() => {
      dispatch(setEmailLoggato(null))
      alert('logout success')
    }, [])

    function getpal(){
      async function getpalesss(){
          const pal=await getPalestraFromUrl(null)
          setpalestra(pal)
      }
      getpalesss()
    }
  
    useEffect(() =>{
      getpal()
      if (tokenprovider){
        confermaSocialLogin()
      }
    },[tokenprovider])


    return (
        <div>
          {palestra?palestra.palestra:""} 
            {
            !localStorage.getItem('email')&&!email&&
                <div>
<LoginSocialFacebook
          appId="410867880057409"
          //"614687543640866" // physicolab
          //"410867880057409"  <==annotatio
          fieldsProfile={
            'first_name,last_name,middle_name,name,email'
          }
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri="https://annotatio.online/"
          //"https://physicolab.it" // <==annotatio
          //"https://annotatio.online/" //<==annotatio
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            setemaildelsocial(provider.data.email)
            settokenprovider(provider.data.accessToken)
            //confermaSocialLogin(provider.data.email);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>

        <LoginSocialGoogle
          client_id="1008305227109-rahajufb101r59qvk7a8np7jh9hpq3mi.apps.googleusercontent.com"
          //"694010366897-pbddlneorjfc7su8ta6q47qabmq4g6g5.apps.googleusercontent.com" //physicolab
          //"1008305227109-rahajufb101r59qvk7a8np7jh9hpq3mi.apps.googleusercontent.com" <== annotatio.online
          onLoginStart={onLoginStart}
scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
fields="email"
          onResolve={( provider, data ) => {
            console.log(provider.provider,"provider")
            console.log(provider.data, "data")
            setemaildelsocial(provider.data.email)
            settokenprovider(provider.data.access_token)
            //confermaSocialLogin(provider.data.email);
            
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>

        <hr />
        Oppure specifica qui la tua email di registrazione
        <br />
        <GoogleReCaptchaProvider 
        reCaptchaKey="6LeLySYaAAAAAAkxF-AI4qDkwEqoYqhfPlwA88ut">
          <div>
            <div className="row">
              <div className="col-md-3">
              <input type="text" class="form-control form-control-lg" style={{boxSizing: "border-box"}} name="emaillogin" value={emaillogin} onChange={(e) => setemaillogin(e.target.value)} />
              </div>
              <div className="col-md-1">
              <button onClick={entra}>Login</button>
              </div>

            </div>
                <GoogleReCaptcha
                        onVerify={token => {
                          setcaptchatoken(token);
                        }}
                  />
          </div>
            </GoogleReCaptchaProvider>
            <hr />
            {

            }
            <hr />
            Oppure <a href="/annotatiou-register">Registrati</a>
            <br />
            </div>
           
            }
        </div>
    )
          }