import axios from 'axios';
import serverInfo from '../util/globals';

export async function insutente(email, nome, cognome, telefono, captcha) {
    const formData = new FormData();
      formData.append('id',0);
      formData.append('email', email);
      
      formData.append('nome', nome?nome:'');//ok
      formData.append('cognome', cognome?cognome:'');//ok
      formData.append('telefono',telefono?telefono:'');//ok
      formData.append('captcha',captcha?captcha:'');//ok
      const response = await axios.post(serverInfo.namesec+'/wp/insUser',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          //il token non serve perchè l'utente ancora non esiste. Per questo c'è il captcha token 
          //'Authorization' :'Bearer '+token,
          //'pantsandtshirt':localStorage.getItem('pantsandtshirt')
        },
      },
    );
    return response.data;
}