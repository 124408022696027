import axios from 'axios';
import serverInfo from '../util/globals';

axios.defaults.withCredentials=true;

export default async function gettoken(email, token){
  console.log('token '+token)
  if (token){
    return token
  }
  const formData = new FormData();
  formData.append('website', serverInfo.domain);
  formData.append('email', email);
  formData.append('appid', 'pluginadmin');
  const response = await axios.post(serverInfo.name+ '/token',
    formData,    
    {
      withCredentials: true,
      credentials:'include',
      headers: { 
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        
        'Authorization' :'Bearer '+token
        
      },
    },
  );
  
  return response;
}