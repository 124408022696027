import axios from "axios";
import serverInfo from "../util/globals";
axios.defaults.withCredentials = true

export async function cercaPalestre(commerciante) {
    const formData = new FormData();
    formData.append('commerciante', commerciante);
    const response = await axios.post(
      serverInfo.name+'/cercaCommercianti',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'pantsandtshirt':localStorage.getItem('pantsandtshirt')
        },
      },
    );
    return response.data;
  }

  export async function getPalestra(idpalestra) {
    const formData = new FormData();
    formData.append('palestra', idpalestra);
    const response = await axios.post(
      serverInfo.namesec+'/wp/getPalestraSelezionataRest',
      formData,
      {withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          // 'Authorization' :'Bearer '+window.$t,
          // 'pantsandtshirt':localStorage.getItem('pantsandtshirt')
        },
      },
    );
    console.log(response.headers);
    return response.data;
  }

  export async function getPalestraByHost(host, token) {
    const formData = new FormData();
    formData.append('website', host);
    const response = await axios.post(
      serverInfo.namesec+'/wp/getCompany',
      formData,
      {
        withCredentials:true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          // 'Authorization' :'Bearer '+token,
          // 'pantsandtshirt':localStorage.getItem('pantsandtshirt')
        },
      },
    );
    console.log(response.headers);
    return response.data;
  }

  export async function getSocio(idsocio) {
    const formData = new FormData();
    formData.append('idsocio', idsocio);
    const response = await axios.post(
      serverInfo.name+'/getCollaboratore',
      formData,
      {
        withCredentials:true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'pantsandtshirt':localStorage.getItem('pantsandtshirt')
        },
      },
    );
    return response.data;
  }

export async function getpalestreassociate(email, idpalestra, token){
    const formData = new FormData();
    formData.append('email',email);
    if (idpalestra){
      formData.append('palestra',idpalestra);
    }
    const response = await axios.post(serverInfo.name+"/getPalestreAssociate2", 
    formData,
    {
      withCredentials:true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token,
        'pantsandtshirt':localStorage.getItem('pantsandtshirt')
      },
    },
    );
    return response.data;
}

export async function aggiornaPalestra(email, idpalestra, nomepalestra, descrizioneattivita, logo, indirizzo, citta, cap, piva, telefono, emailpalestra,
  gpscoords, website, subdomain, nazione, urlicon, urlbadge, wpApplicationPass, orarioApertura1, orarioChiusura1, orarioApertura2, orarioChiusura2){
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email',email);
  formData.append('lic','00000000');
  
  formData.append('palestra', nomepalestra);
  formData.append('id',idpalestra);
  formData.append('descrizione', descrizioneattivita);
  formData.append('logo', logo);
  formData.append('indirizzo', indirizzo);
  formData.append('citta', citta);
  formData.append('cap', cap);
  formData.append('piva', piva);
  formData.append('telefono', telefono);
  formData.append('emailpalestra', emailpalestra);
  formData.append('gpscoords', gpscoords);
  formData.append('websitepalestra', website);
  formData.append('subdomain', subdomain);
  formData.append('nazione',nazione);
  formData.append('urlIcon',urlicon?urlicon:'');//ok
  formData.append('urlBadge',urlbadge?urlbadge:'');//ok
  formData.append('appPw',wpApplicationPass?wpApplicationPass:'');
  formData.append('orarioApertura1',orarioApertura1?orarioApertura1:'');
  formData.append('orarioChiusura1',orarioChiusura1?orarioChiusura1:'');
  formData.append('orarioApertura2',orarioApertura2?orarioApertura2:'');
  formData.append('orarioChiusura2',orarioChiusura2?orarioChiusura2:'');
  const response = await axios.post(serverInfo.name+'/aggiornaPalestra', 
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'pantsandtshirt':localStorage.getItem('pantsandtshirt')
    },
  },
  );
  return response.data;
}

export async function nuovaPalestra(email, nomepalestra, descrizioneattivita, logo, indirizzo, citta, cap, piva, telefono, emailpalestra,
  gpscoords, website, subdomain, nazione, urlicon, urlbadge, wpApplicationPass, orarioApertura1, orarioChiusura1, orarioApertura2, orarioChiusura2, nomeamministratore){
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email',email);
  formData.append('lic','00000000');

  formData.append('palestra', nomepalestra);
  formData.append('descrizione', descrizioneattivita);
  formData.append('logo', logo);
  formData.append('indirizzo', indirizzo);
  formData.append('citta', citta);
  formData.append('cap', cap);
  formData.append('piva', piva);
  formData.append('telefono', telefono);
  formData.append('emailpalestra', emailpalestra);
  formData.append('gpscoords', gpscoords);
  formData.append('websitepalestra', website);
  formData.append('subdomain', subdomain);
  formData.append('nazione',nazione);
  formData.append('urlIcon',urlicon?urlicon:'');//ok
  formData.append('urlBadge',urlbadge?urlbadge:'');//ok
  formData.append('appPw',wpApplicationPass?wpApplicationPass:'');
  formData.append('orarioApertura1',orarioApertura1?orarioApertura1:'');
  formData.append('orarioChiusura1',orarioChiusura1?orarioChiusura1:'');
  formData.append('orarioApertura2',orarioApertura2?orarioApertura2:'');
  formData.append('orarioChiusura2',orarioChiusura2?orarioChiusura2:'');
  formData.append('nomeamministratore', nomeamministratore)
  const response = await axios.post(serverInfo.name+'/nuovaPalestra', 
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'pantsandtshirt':localStorage.getItem('pantsandtshirt')
    },
  },
  );
  return response.data;
}

export async function eliminaPalestra(email,idpalestra, idsocio){
  const formData = new FormData();
  formData.append('email',email);
  formData.append('website', 'annotatio.online');
  formData.append('palestra',idpalestra);
  formData.append('lic','00000000');
  formData.append('idsocio',idsocio);
  const response = await axios.post(serverInfo.name+"/eliminaPalestra", 
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'pantsandtshirt':localStorage.getItem('pantsandtshirt')
    },
  },
  );
  return response.data;
}