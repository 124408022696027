import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import RiquadroRicercaAttivita from './riquadri/RiquadroRicercaAttivita';
import NuovaPalestra from './palestra/NuovaPalestra';
import { Provider } from 'react-redux';
import store from './STORE/store'
import AppRoute from './AppRoute';
import LoadMainThings from './util/LoadMainThings';
import LoginUtil from './login/LoginUtil';
import NuovoUtenteAnagrafica from './utente/NuovoUtenteAnagrafica';

var ricercaAttivita=document.getElementById("ricercaAttivita");
if (ricercaAttivita){
  const email=ricercaAttivita.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=ricercaAttivita.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricercaAttivita.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const linkannotatio2=ricercaAttivita.getAttribute("linkannotatio2");
  console.log("linkannotatio2 "+linkannotatio2)

  ReactDOM.render(
        <RiquadroRicercaAttivita email={email} link={linkannotatio} link2={linkannotatio2}/>
      ,
      ricercaAttivita
    );
}

var nuovapalestra=document.getElementById("nuovapalestra");
if (nuovapalestra){
  const email=nuovapalestra.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=nuovapalestra.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=nuovapalestra.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  ReactDOM.render(
        <NuovaPalestra email={email} link={linkannotatio}/>
      ,
      nuovapalestra
    );
}

var loginutil=document.getElementById("annotatiouutillogin");
console.log('annotatiologin')
if (loginutil){
  const email=loginutil.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const linkannotatio=loginutil.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=loginutil.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
    <LoadMainThings email={email} />
    <LoginUtil email={email} showlogin={false} />
  </Provider>,
      loginutil
    );
}

var loginutilcompl=document.getElementById("annotatiouutillogincompl");
console.log('annotatiouutillogincompl')
if (loginutilcompl){
  const email=loginutilcompl.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const linkannotatio=loginutilcompl.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=loginutilcompl.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
    <LoadMainThings email={email} />
    <LoginUtil email={email} showlogin={true} />
  </Provider>,
      loginutilcompl
    );
}

var annotatiologin=document.getElementById("annotatioulogin");
console.log('annotatiologin')
if (annotatiologin){
  const email=annotatiologin.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const linkannotatio=annotatiologin.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=annotatiologin.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
    <AppRoute showtoolbar={false} showheader={false} email={email} 
    initialpage="/annotatiou-login/"
    t={tkn} />
  </Provider>,
      annotatiologin
    );
}

/************+ */
//INSERIMENTO NUOVO UTENTE
/************ */
var insutente=document.getElementById("annotatiou-insutente");
if (insutente){
  const email=insutente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const key=insutente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=insutente.getAttribute("linkannotatio");

  const nome=insutente.getAttribute("first_name");
  const cognome=insutente.getAttribute("last_name");
  const telefono=insutente.getAttribute("telefono");

  const tkn=insutente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
    <AppRoute showtoolbar={false} showheader={false} email={email} 
    initialpage="/annotatiou-insutente/"
    link={linkannotatio}
    t={tkn} />
  </Provider>,
      insutente
    );
  }
  /************+ */
//INSERIMENTO NUOVO UTENTE CON ANAGRAFICA
/************ */
var insutenteanag=document.getElementById("annotatiou-insutenteanag");
if (insutenteanag){
  const email=insutenteanag.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const key=insutenteanag.getAttribute("key");
  localStorage.setItem('key',key);

  ReactDOM.render(
    <Provider store={store}>
    <NuovoUtenteAnagrafica />
  </Provider>,
      insutenteanag
    );
  }
    /************+ */
//DETTAGLIO UTENTE
/************ */
var dettutente=document.getElementById("annotatiou-dettutente");
if (dettutente){
  const email=dettutente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
   }

  const key=dettutente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettutente.getAttribute("linkannotatio");

  const nome=dettutente.getAttribute("first_name");
  const cognome=dettutente.getAttribute("last_name");
  const telefono=dettutente.getAttribute("telefono");

  const tkn=dettutente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
    <AppRoute showtoolbar={false} showheader={false} email={email} 
    initialpage="/annotatiou-dettutente/"
    link={linkannotatio}
    t={tkn} />
  </Provider>,
      dettutente
    );
}

reportWebVitals();